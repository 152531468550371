<template>
	<div class="pui-form">
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-col cols="12">
					<v-row>
						<v-col cols="5">
							<pui-text-field
								:id="`visitstatuscode-visitstatuspa`"
								v-model="model.visitstatuscode"
								:label="$t('form.visitstatuspa.visitstatuscode')"
								:disabled="formDisabled"
								required
								toplabel
								maxlength="2"
							></pui-text-field>
						</v-col>
						<v-col cols="5">
							<pui-text-field
								:id="`description-visitstatuspa`"
								v-model="model.description"
								:label="$t('form.visitstatuspa.description')"
								required
								toplabel
								maxlength="50"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="4">
							<pui-text-field
								:id="`colorhex-visitstatuspa`"
								v-model="model.colorhex"
								:label="$t('form.visitstatuspa.colorhex')"
								required
								toplabel
								maxlength="7"
							></pui-text-field>
						</v-col>
						<v-col cols="4">
							<pui-text-field
								:id="`colorhexoutline-visitstatuspa`"
								v-model="model.colorhexoutline"
								:label="$t('form.visitstatuspa.colorhexoutline')"
								required
								toplabel
								maxlength="7"
							></pui-text-field>
						</v-col>
					</v-row>
				</v-col>
			</v-row>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	name: 'visitstatuspa-form',
	mixins: [PuiFormMethodsMixin],
	components: {},
	data() {
		return {
			modelName: 'visitstatuspa'
		};
	},
	methods: {
		afterGetData() {
			// Do something after get data from server
		}
	},
	computed: {},
	created() {}
};
</script>
